.View_Mater_Form{
    border: 1px solid black;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.View_Master_Input{
    width: 100%;
}