.Add_Game_Main{
    display: flex;
    justify-content: center;
    background-color: #313131;  
    background-image: url('../../assets/images/backgroud.jpg');
    background-size: cover;
    background-attachment: fixed;
    
}
.Add_Game_Border{
    width: 500px;
    padding: 20px;
    border: 1px solid white;
}
.Add_Game_Input{
    width: 100%;
    border: 1px solid grey;
    color: gray;

}
.Add_Game_Border ::placeholder{
    color: white;
}
.ADD_GAME_BUTTON{
    background: linear-gradient(180deg, rgb(246, 200, 16), rgb(246, 121, 5));
    color: black;
    border: none;
}