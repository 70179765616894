.bgContent{
  background-color: #313131;
  min-height: calc(100vh - 70px);
  position: relative;
  z-index: 10;
  margin-top: 70px !important;
}

.bgContent-iframe{
  background-color: #313131;
  min-height: calc(100vh - 70px);
  position: relative;
  z-index: 10;
}

.loader{
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: #313131;
}

.fs-1 {font-size: 1px;}

.fs-2 {font-size: 2px;}

.fs-3 {font-size: 3px;}

.fs-4 {font-size: 4px;}

.fs-5 {font-size: 5px;}

.fs-6 {font-size: 6px;}

.fs-7 {font-size: 7px;}

.fs-8 {font-size: 8px;}

.fs-9 {font-size: 9px;}

.fs-10 {font-size: 10px;}

.fs-11 {font-size: 11px;}

.fs-12 {font-size: 12px;}

.fs-13 {font-size: 13px;}

.fs-14 {font-size: 14px;}

.fs-15 {font-size: 15px;}

.fs-16 {font-size: 16px;}

.fs-17 {font-size: 17px;}

.fs-18 {font-size: 18px;}

.fs-19 {font-size: 19px;}

.fs-20{font-size: 20px;}

.scrollTop{
  position: fixed;
  bottom: 25px;
  right: 20px;
  z-index: 10000;
  display: none;
}

.cursor-pointer{
  cursor: pointer;
}

.DescriptionHeading{
  background: linear-gradient(180deg,#f6c810,#f67905);
  border-radius: 5px;
  padding: 10px;
}

.iframe-container{
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.iframe-container iframe{
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% + 45px);
  width: 100%;
  border: none;
}

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 5px; 
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}
::-webkit-scrollbar-button {
  background-color: #ddd;
}


@media only screen and (max-width: 1000px) {
  .iframe-container iframe {
      height: calc(100%);
  }
}
.errorMessage{
  color: #ff3535;
}