.login_page{
    width: 100%;
    height: 100vh;
    /* background-color: rgb(219, 238, 245); */
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/images/backgroud.jpg');
    background-size: cover;
    padding: 20px;
}

.Login_header{
    text-align: center;
    margin-top: 10px;
    color: rgb(242, 242, 242);
}

.Login_form_section{
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding-left: 10px;
    border-radius: 3px;
    border: none;
}
.Login_button{
    padding: 4px 25px;
    margin-top: 30px;
    text-align: center;
    border-radius: 3px;
    border: none;
    background-color: rgb(117, 117, 230);
    color: white;
}
.Login_main-section{
    border: 1px solid #dedede;
    border-radius: 10px;
    height: 300px;
    background-color: rgba(79, 79, 79, 0.302);
}
.Login_width{
    width: 100%;
    max-width: 450px;
    height: auto;
}
.login_section{
    padding: 20px;
}

.input{
    width: 100%;
    padding: 5px;
    background-color: transparent;
    border: 1px solid #dedede;
    color: white;
    border-radius: 5px;
}
.input:focus{
    outline: none;
}