.my-container{
    width: 63%;
    margin: 0 auto !important;
}


@media only screen and (max-width: 600px) {
    .my-container{
        width: 100%;
    }
}