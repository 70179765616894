.header{
    background-color: rgb(37, 37, 37);
    color: white;
    padding: 5px;
    height: 70px;
    box-shadow: 0px -10px 20px 0px white;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
}
.menu{
    margin-bottom: 0;
}
.menu a{
    display: inline;
    padding: 7px 12px;
    margin: 0 5px;
    color: white;
    text-decoration: none;
    transition: 0.5s;
    border-radius: 5px;
}
.menu a:hover{
    background: linear-gradient(180deg, rgb(246, 200, 16) , rgb(246, 121, 5));
    color: black;
}
.menu a.active{
    background: linear-gradient(180deg, rgb(246, 200, 16) , rgb(246, 121, 5));
    color: black;
}