
.gameCard{
    color: rgba(255, 255, 255, 0.623);
}
.gameCard img{
    border-radius: 20px;
    cursor: pointer;
    transition: 0.5s;
    width: 100%;
    height: 110px;
}
.gameCard img:hover{
    transform: scale(1.05);
}

@media only screen and (max-width: 992px) {
    .gameCard img {
        height: 130px;
    }
}
@media only screen and (max-width: 768px) {
    .gameCard img {
        height: 120px;
    }
}

@media only screen and (max-width: 576px) {
    .gameCard img {
        height: 120px;
    }
}