.gameBtn {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background: linear-gradient(180deg, rgb(246, 200, 16), rgb(246, 121, 5));
    transition: 0.5s;
    font-weight: 500;
}

.gameBtn:hover {
    transform: scale(1.1);
}

.common-game-bg-container {
    position: relative;
    width: 100%;
    height: 600px;
    height: auto;
}

.common-game-bg {
    background-image: url('./../../assets/images/common_game_bg.jpeg');
    filter: blur(8px);
    width: 100%;
    height: 600px;
    background-position: bottom;
    box-shadow: inset 0 0 0 2000px rgba(15, 15, 15, 0.9);
}

.common-game-bg-overlay-banner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.common-game-bg-overlay-banner .center-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.common-game-bg-overlay-banner .center-content img {
    height: 200px;
    width: 300px;
    border-radius: 10px;
    animation-name: ZoomInOut;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.description {
    font-size: 20px;
}

.tag {
    background-color: aliceblue;
    border-radius: 30px;
    padding: 5px 20px;
}

@media only screen and (max-width: 1000px) {
    .bannerImg {
        width: 100%;
    }

    .description {
        font-size: 14px;
    }

    .tag {
        font-size: 14px;
    }
}

@media only screen and (max-width: 400px) {
    .bannerImg {
        height: 300px;
    }

    .description {
        font-size: 12px;
    }

    .tag {
        font-size: 12px;
    }
}

@keyframes ZoomInOut {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}


.main-container {
    width: 63%;
    margin: 0 auto !important;
}


@media only screen and (max-width: 600px) {
    .main-container {
        width: 100%;
    }

    .common-game-bgn-container {
        position: relative;
        width: 100%;
        min-height: "300px";
        height: 'auto'
    }

    .common-game-bg {
        filter: blur(20px);
        width: 100%;
        height: 300px;
        background-position: bottom;
        box-shadow: inset 0 0 0 2000px rgba(28, 28, 28, 0.7);
    }

    .common-game-bg-overlay-banner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .modal {
        --bs-modal-margin: 0 !important;
    }
}

.adbutton {
    padding: 6px 20px;
    border-radius: 9999px;
    border: 1px solid #fff;
    background: rgb(51 144 255);
    transition: 0.5s;
    font-weight: 500;
    color: white;
    line-height: 26px;
}

.adbutton:hover {
    transform: scale(1.1);
}

.mt-m-1 {
    margin-top: -0.25rem !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.modal-header {
    width: 100%;
    font-family: 'Roboto', arial, sans-serif;
    display: flex;
    font-size: 18px;
    background-color: #aeaeae;
    color: black;
    border-bottom: 0;
}

.modal-title {
    font-size: 14px;
    margin-left: 36%;
}

.modal-content {
    border: none;
}

.modal-content {
    background: #26262680;
    backdrop-filter: blur(5px);
}