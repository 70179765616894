.footer{
    width: 100%;
    background-color: rgb(37, 37, 37);
    color: white;
    padding: 5px;
}
.menuLinks a{
    display: block;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.624);
}
.footerLinks{
    justify-content: end;
}


@media only screen and (max-width: 767px) {
    .footerLinks{
        justify-content: start;
        margin-top: 30px;
    }
}